var options = {
    chart: {
        height: 380,
        type: "line",
        zoom: {
            enabled: !1
        },
        toolbar: {
            show: !1
        }
    },
    colors: ["#556ee6", "#34c38f"],
    dataLabels: {
        enabled: !0
    },
    stroke: {
        width: [3, 3],
        curve: "straight"
    },
    series: [{
        name: "High - 2018",
        data: [26, 24, 32, 36, 33, 31, 33]
    }, {
        name: "Low - 2018",
        data: [14, 11, 16, 12, 17, 13, 12]
    }],
    title: {
        text: "Average High & Low Temperature",
        align: "left"
    },
    grid: {
        row: {
            colors: ["transparent", "transparent"],
            opacity: .2
        },
        borderColor: "#f1f1f1"
    },
    markers: {
        style: "inverted",
        size: 6
    },
    xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        title: {
            text: "Month"
        }
    },
    yaxis: {
        title: {
            text: "Temperature"
        },
        min: 5,
        max: 40
    },
    legend: {
        position: "top",
        horizontalAlign: "right",
        floating: !0,
        offsetY: -25,
        offsetX: -5
    },
    responsive: [{
        breakpoint: 600,
        options: {
            chart: {
                toolbar: {
                    show: !1
                }
            },
            legend: {
                show: !1
            }
        }
    }]
};
(chart = new ApexCharts(document.querySelector("#line_chart_datalabel"), options)).render();
//
options = {
    chart: {
        height: 380,
        type: "line",
        zoom: {
            enabled: !1
        },
        toolbar: {
            show: !1
        }
    },
    colors: ["#556ee6", "#f46a6a", "#34c38f"],
    dataLabels: {
        enabled: !1
    },
    stroke: {
        width: [3, 4, 3],
        curve: "straight",
        dashArray: [0, 8, 5]
    },
    series: [{
        name: "Session Duration",
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
    }, {
        name: "Page Views",
        data: [36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35]
    }, {
        name: "Total Visits",
        data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49]
    }],
    title: {
        text: "Page Statistics",
        align: "left"
    },
    markers: {
        size: 0,
        hover: {
            sizeOffset: 6
        }
    },
    xaxis: {
        categories: ["01 Jan", "02 Jan", "03 Jan", "04 Jan", "05 Jan", "06 Jan", "07 Jan", "08 Jan", "09 Jan", "10 Jan", "11 Jan", "12 Jan"]
    },
    tooltip: {
        y: [{
            title: {
                formatter: function (e) {
                    return e + " (mins)"
                }
            }
        }, {
            title: {
                formatter: function (e) {
                    return e + " per session"
                }
            }
        }, {
            title: {
                formatter: function (e) {
                    return e
                }
            }
        }]
    },
    grid: {
        borderColor: "#f1f1f1"
    }
};
(chart = new ApexCharts(document.querySelector("#line_chart_dashed"), options)).render();

//
options = {
    chart: {
        height: 350,
        type: "area"
    },
    dataLabels: {
        enabled: !1
    },
    stroke: {
        curve: "smooth",
        width: 3
    },
    series: [{
        name: "series1",
        data: [34, 40, 28, 52, 42, 109, 100]
    }, {
        name: "series2",
        data: [32, 60, 34, 46, 34, 52, 41]
    }],
    colors: ["#556ee6", "#34c38f"],
    xaxis: {
        type: "datetime",
        categories: ["2018-09-19T00:00:00", "2018-09-19T01:30:00", "2018-09-19T02:30:00", "2018-09-19T03:30:00", "2018-09-19T04:30:00", "2018-09-19T05:30:00", "2018-09-19T06:30:00"]
    },
    grid: {
        borderColor: "#f1f1f1"
    },
    tooltip: {
        x: {
            format: "dd/MM/yy HH:mm"
        }
    }
};
(chart = new ApexCharts(document.querySelector("#spline_area"), options)).render();


//Session wise Income and Expense Report
options = {
    chart: {
        height: 350,
        type: "bar",
        toolbar: {
            show: !1
        }
    },
    plotOptions: {
        bar: {
            horizontal: !1,
            columnWidth: "70%",
            borderRadius: 3,
        }
    },
    dataLabels: {
        enabled: !1
    },
    stroke: {
        show: !0,
        width: 2,
        colors: ["transparent"]
    },
    series: [{
        name: "Income",
        data: [46, 57, 59, 54, 62, 58, 64, 60, 66, 70, 80, 78]
    }, {
        name: "Expense",
        data: [37, 42, 38, 26, 47, 50, 54, 55, 43, 55, 30, 45]
    }],
    colors: ["#34c38f", "#f46a6a"],
    xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    },
    yaxis: {
        title: {
            text: "₹ (Thousands)"
        }
    },
    grid: {
        borderColor: "#f1f1f1"
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (e) {
                return " ₹ " + e + " Thousands"
            }
        }
    }
};
(chart = new ApexCharts(document.querySelector("#sessincomexp"), options)).render();

//Instution Growth Stastitics
options = {
    chart: {
        height: 235,
        type: "bar",
        toolbar: {
            show: !1
        }
    },
    plotOptions: {
        bar: {
            dataLabels: {
                position: "top",
            }
        }
    },
    dataLabels: {
        enabled: !0,
        formatter: function (e) {
            return e + "%"
        },
        offsetY: -20,
        style: {
            fontSize: "12px",
            colors: ["#304758"]
        }
    },
    series: [{
        name: "Inflation",
        data: [2.5, 3.2, 5, 10.1, 4.2, 3.8, 3, 2.4, 4, 1.2, 3.5, .8]
    }],
    colors: ["#556ee6"],
    grid: {
        borderColor: "#f1f1f1"
    },
    xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: "top",
        labels: {
            offsetY: -18
        },
        axisBorder: {
            show: !1
        },
        axisTicks: {
            show: !1
        },
        crosshairs: {
            fill: {
                type: "gradient",
                gradient: {
                    colorFrom: "#D8E3F0",
                    colorTo: "#BED1E6",
                    stops: [0, 100],
                    opacityFrom: .4,
                    opacityTo: .5
                }
            }
        },
        tooltip: {
            enabled: !0,
            offsetY: -35
        }
    },
    fill: {
        gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: .25,
            gradientToColors: void 0,
            inverseColors: !0,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100]
        }
    },
    yaxis: {
        axisBorder: {
            show: !1
        },
        axisTicks: {
            show: !1
        },
        labels: {
            show: !1,
            formatter: function (e) {
                return e + "%"
            }
        }
    },
    title: {
        text: "Monthly Inflation in Argentina, 2002",
        floating: !0,
        offsetY: 320,
        align: "center",
        style: {
            color: "#444"
        }
    }
};
(chart = new ApexCharts(document.querySelector("#instugrowth"), options)).render();

//====
options = {
    chart: {
        height: 400,
        type: "bar",
        toolbar: {
            show: !1
        }
    },
    plotOptions: {
        bar: {
            horizontal: !0
        }
    },
    dataLabels: {
        enabled: !1
    },
    series: [{
        data: [1380, 430, 450, 475, 550, 1584, 780, 1100, 1220, 1365, 1365, 1365]
    }],
    colors: ["#34c38f"],
    grid: {
        borderColor: "#f1f1f1"
    },
    xaxis: {
        categories: ["STD I", "STD II", "STD III", "STD IV", "STD V", "STD VI", "STD VII", "STD VIII", "STD IX", "STD X", "STD XI", "STD XII"]
    }
};
(chart = new ApexCharts(document.querySelector("#bar_chart"), options)).render();

//Organization Progress Stastitics

options = {
    chart: {
        height: 335,
        type: "line",
        stacked: !1,
        toolbar: {
            show: !1
        }
    },
    stroke: {
        width: [0, 2, 4],
        curve: "smooth"
    },
    plotOptions: {
        bar: {
            columnWidth: "50%"
        }
    },
    colors: ["#f46a6a", "#556ee6", "#34c38f"],
    series: [{
        name: "Total Admissions",
        type: "column",
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 36]
    }, {
        name: "Total Enquires",
        type: "area",
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 45]
    }, {
        name: "Total Registrations",
        type: "line",
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 35]
    }],
    fill: {
        opacity: [.85, .25, 1],
        gradient: {
            inverseColors: !1,
            shade: "light",
            type: "vertical",
            opacityFrom: .85,
            opacityTo: .55,
            stops: [0, 100, 100, 100]
        }
    },

    legend: {
        offsetY: -10
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
        title: {
            text: ""
        }
    },
    tooltip: {
        shared: !0,
        intersect: !1,
        y: {
            formatter: function (e) {
                return void 0 !== e ? e.toFixed(0) + "" : e
            }
        }
    },
    grid: {
        borderColor: "#f1f1f1"
    }
};
(chart = new ApexCharts(document.querySelector("#orgprogress"), options)).render();

//
options = {
    chart: {
        height: 380,
        type: "radialBar"
    },
    plotOptions: {
        radialBar: {
            dataLabels: {
                name: {
                    fontSize: "22px"
                },
                value: {
                    fontSize: "16px"
                },
                total: {
                    show: !0,
                    label: "Total",
                    formatter: function (e) {
                        return 249
                    }
                }
            }
        }
    },
    series: [44, 55, 67, 83],
    labels: ["Computer", "Tablet", "Laptop", "Mobile"],
    colors: ["#556ee6", "#34c38f", "#f46a6a", "#f1b44c"]
};
(chart = new ApexCharts(document.querySelector("#radial_chart"), options)).render();
options = {
    chart: {
        height: 320,
        type: "pie"
    },
    series: [44, 55, 41, 17, 15],
    labels: ["Series 1", "Series 2", "Series 3", "Series 4", "Series 5"],
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
    legend: {
        show: !0,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: !1,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10
    },
    responsive: [{
        breakpoint: 600,
        options: {
            chart: {
                height: 240
            },
            legend: {
                show: !1
            }
        }
    }]
};
(chart = new ApexCharts(document.querySelector("#pie_chart"), options)).render();

//Religin Wise Student
var chart;
options = {
    chart: {
        height: 320,
        type: "donut"
    },
    series: [50, 15, 15, 20],
    labels: ["Hindu", "Muslim", "Sikh", "Christians"],
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
    legend: {
        show: !0,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: !1,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10
    },
    responsive: [{
        breakpoint: 600,
        options: {
            chart: {
                height: 240
            },
            legend: {
                show: !1
            }
        }
    }]
};
(chart = new ApexCharts(document.querySelector("#religinwisestudent"), options)).render();

//Category Wise Student
var chart;
options = {
    chart: {
        height: 320,
        type: "donut"
    },
    series: [40, 30, 15, 15],
    labels: ["Gen", "OBC", "SC", "ST"],
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
    legend: {
        show: !0,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: !1,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10
    },
    responsive: [{
        breakpoint: 600,
        options: {
            chart: {
                height: 240
            },
            legend: {
                show: !1
            }
        }
    }]
};
(chart = new ApexCharts(document.querySelector("#catwisestudent"), options)).render();

//=============================================================

//Session Wise Student Report
var options = {
    series: [{
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380, 1020, 1210]
    }],
    chart: {
        type: 'bar',
        height: 400
    },
    plotOptions: {
        bar: {
            borderRadius: 3,
            horizontal: true,
        }
    },
    dataLabels: {
        enabled: false
    },
    xaxis: {
        categories: ['STD I', 'STD II', 'STD III', 'STD IV', 'STD V', 'STD VI', 'STD VII',
            'STD VIII', 'STD IX', 'STD X', 'STD XI', 'STD XII'
        ],
    }
};

var chart = new ApexCharts(document.querySelector("#sesswisestudent"), options);
chart.render();



//Class wise Student Summary
var options = {
    series: [44, 55, 41, 17, 15],
    chart: {
        type: 'donut',
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
        }
    }]
};

var chart = new ApexCharts(document.querySelector("#classwisestudent"), options);
chart.render();


//DEPARTMENT WISE SALARY

var options = {
    series: [{
        data: [40052, 10052, 20052, 50052, 30052, 20052]
    }],
    chart: {
        type: 'bar',
        height: 400
    },
    plotOptions: {
        bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            borderRadius: 3,
            dataLabels: {
                position: 'bottom'
            },
        }
    },
    colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
        '#f48024', '#69d2e7'
    ],
    dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
            colors: ['#fff']
        },
        formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
        },
        offsetX: 0,
        dropShadow: {
            enabled: true
        }
    },
    stroke: {
        width: 1,
        colors: ['#fff']
    },
    xaxis: {
        categories: ['Mathematics', 'Accounts', 'Sales', 'Marketing', 'Science', 'Finance'
        ],
    },
    yaxis: {
        labels: {
            show: false
        }
    },
    tooltip: {
        theme: 'dark',
        x: {
            show: false
        },
        y: {
            title: {
                formatter: function () {
                    return ''
                }
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#deptwisesal"), options);
chart.render();




// 100% STACKED BAR CHARTS

var options = {
    series: [{
        name: 'Marine Sprite',
        data: [44, 55, 41, 37, 22, 43, 21]
    }, {
        name: 'Striking Calf',
        data: [53, 32, 33, 52, 13, 43, 32]
    }, {
        name: 'Tank Picture',
        data: [12, 17, 11, 9, 15, 11, 20]
    }, {
        name: 'Bucket Slope',
        data: [9, 7, 5, 8, 6, 9, 4]
    }, {
        name: 'Reborn Kid',
        data: [25, 12, 19, 32, 25, 24, 10]
    }],
    chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%'
    },
    plotOptions: {
        bar: {
            horizontal: true,
        },
    },
    stroke: {
        width: 1,
        colors: ['#fff']
    },
    title: {
        text: '100% Stacked Bar'
    },
    xaxis: {
        categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val + "K"
            }
        }
    },
    fill: {
        opacity: 1

    },
    legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
    }
};

var chart = new ApexCharts(document.querySelector("#chart1"), options);
chart.render();


// LINE COLUMN AREA
var options = {
    series: [{
        name: 'Website Blog',
        type: 'column',
        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
    }, {
        name: 'Social Media',
        type: 'line',
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
    }],
    chart: {
        height: 350,
        type: 'line',
    },
    stroke: {
        width: [0, 4]
    },
    title: {
        text: 'Traffic Sources'
    },
    dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
    },
    labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
    xaxis: {
        type: 'datetime'
    },
    yaxis: [{
        title: {
            text: 'Website Blog',
        },

    }, {
        opposite: true,
        title: {
            text: 'Social Media'
        }
    }]
};

var chart = new ApexCharts(document.querySelector("#chart2"), options);
chart.render();


//monochrome-pie

var options = {
    series: [25, 15, 44, 55, 41, 17],
    chart: {
        width: '100%',
        type: 'pie',
    },
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    theme: {
        monochrome: {
            enabled: true
        }
    },
    plotOptions: {
        pie: {
            dataLabels: {
                offset: -5
            }
        }
    },
    title: {
        text: ""
    },
    dataLabels: {
        formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, val.toFixed(1) + '%']
        }
    },
    legend: {
        show: false
    }
};
var chart = new ApexCharts(document.querySelector("#chart3"), options);
chart.render();

//DEPT WISE EMPLOYEE
var colors = [
    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#546E7A',
    '#26a69a',
    '#D10CE8'
]

var options = {
    series: [{
        data: [50, 22, 10, 88, 50, 25, 97, 78]
    }],
    chart: {
        height: 350,
        type: 'bar',
        events: {
            click: function (chart, w, e) {
                // console.log(chart, w, e)
            }
        }
    },
    colors: colors,
    plotOptions: {
        bar: {
            columnWidth: '60%',
            distributed: true,
            borderRadius: 5,
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    xaxis: {
        categories: [
            ['Front Office'],
            ['Teaching'],
            ['Mathemetics'],
            'Accountant', 'Librarian', 'Marketing', 'Admin', 'HR'
        ],
        labels: {
            style: {
                colors: colors,
                fontSize: '12px'
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#deptwiseemp"), options);
chart.render();

//Session wise Employee Hiring analysis

var options = {
    series: [{
        name: 'Total Applications',
        data: [550, 505, 401, 357, 282, 543, 421]
    }, {
        name: 'Interview Taken',
        data: [530, 500, 400, 300, 200, 402, 410]
    }, {
        name: 'Selected Candidate',
        data: [102, 127, 151, 79, 105, 101, 200]
    }, {
        name: 'Rejected Candidate',
        data: [80, 70, 50, 85, 68, 95, 74]
    }, {
        name: 'Joining',
        data: [75, 102, 109, 302, 205, 204, 100]
    }],
    chart: {
        type: 'bar',
        height: 350,
        stacked: true,
    },
    plotOptions: {
        bar: {
            horizontal: true,
            borderRadius: 3,
        },
    },
    stroke: {
        width: 1,
        colors: ['#fff']
    },
    title: {
        text: ''
    },
    xaxis: {
        categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
        labels: {
            formatter: function (val) {
                return val + "K"
            }
        }
    },
    yaxis: {
        title: {
            text: undefined
        },
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val + "K"
            }
        }
    },
    fill: {
        opacity: 1
    },
    legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
    }
};

var chart = new ApexCharts(document.querySelector("#yearwisehiring"), options);
chart.render();


//Employee Leave and Attendance Analysis

var options = {
    series: [{
        name: 'Present',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 87, 68, 97]
    }, {
        name: 'Work Days',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 81, 68, 17]
    },
    {
        name: 'Absent',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 81, 68, 17]
    },
    {
        name: 'Leave',
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 87, 55, 91]
    }],
    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '70%',
            endingShape: 'rounded',
            borderRadius: 2,
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
        title: {
            text: 'Days'
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val + " Percent"
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#attendanceandleave"), options);
chart.render();

//Employee Joining and Exit Analysis

var options = {
    series: [{
        name: 'Joining',
        type: 'column',
        data: [30, 40, 20, 10, 20, 12, 25, 32, 15, 11]
    }, {
        name: 'Exit',
        type: 'line',
        data: [5, 4, 5, 0, 2, 3, 2, 5, 0, 1, 15, 4]
    }],
    chart: {
        height: 350,
        type: 'line',
        borderRadius: 3,
    },
    stroke: {
        width: [0, 4]
    },
    dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: [{
        title: {
            text: 'Joining',
        },

    }, {
        opposite: true,
        title: {
            text: 'Exit'
        }
    }]
};

var chart = new ApexCharts(document.querySelector("#joinexit"), options);
chart.render();

//Session wise Salary Analysis
var options = {
    series: [{
        name: 'Total Salary Paid ₹ ',
        data: [{
            x: '2011-2012',
            y: 1292
        }, {
            x: '2012-2013',
            y: 4432
        }, {
            x: '2013-2014',
            y: 5423
        }, {
            x: '2014-2015',
            y: 6653
        }, {
            x: '2015-2016',
            y: 8133,
            fillColor: '#EB8C87',
            strokeColor: '#C23829'
        }, {
            x: '2016-2017',
            y: 7132
        }, {
            x: '2017-2018',
            y: 7332
        }, {
            x: '2018-2019',
            y: 7553
        }]
    }],
    chart: {
        height: 350,
        type: 'bar',
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '60%',
            borderRadius: 4,
        },
    },
    stroke: {
        width: 0,
    },
    dataLabels: {
        enabled: false
    },
    yaxis: {
        labels: {
            formatter: function (val) {
                return val / 1000
            }
        }
    },
    fill: {
        opacity: 1,
    },
};

var chart = new ApexCharts(document.querySelector("#sesswisesal"), options);
chart.render();


//Employee Type Analyis
var options = {
    series: [44, 55, 41, 17, 15],
    labels: ["Permanent", "Temporary", "Contract"],
    chart: {
        type: 'donut',
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
        }
    }]
};

var chart = new ApexCharts(document.querySelector("#emptypeanalysis"), options);
chart.render();

//Job Type Analyis
var options = {
    series: [44, 55, 13, 43, 22],
    chart: {
        width: 380,
        type: 'pie',
    },
    labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 300
            },
            legend: {
                position: 'bottom'
            }
        }
    }]
};

var chart = new ApexCharts(document.querySelector("#jobtypeanalysis"), options);
chart.render();

//Class wise Traveller Analysis

var options = {
    series: [{
        name: 'BATCH A',
        data: [550, 505, 401, 357, 282, 543, 421]
    }, {
        name: 'BATCH B',
        data: [530, 500, 400, 300, 200, 402, 410]
    }, {
        name: 'BATCH C',
        data: [102, 127, 151, 79, 105, 101, 200]
    }, {
        name: 'BATCH D',
        data: [75, 102, 109, 302, 205, 204, 100]
    }],
    chart: {
        type: 'bar',
        height: 350,
        stacked: true,
    },
    plotOptions: {
        bar: {
            horizontal: true,
            borderRadius: 3,
        },
    },
    stroke: {
        width: 1,
        colors: ['#fff']
    },
    title: {
        text: ''
    },
    xaxis: {
        categories: ['STD I', 'STD II', 'STD III', 'STD IV', 'STD V', 'STD VI', 'STD VII'],
        labels: {
            formatter: function (val) {
                return val + "K"
            }
        }
    },
    yaxis: {
        title: {
            text: undefined
        },
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val + "K"
            }
        }
    },
    fill: {
        opacity: 1
    },
    legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
    }
};

var chart = new ApexCharts(document.querySelector("#classwisetraveller"), options);
chart.render();

//Route wise Traveller Analysis

var colors = [
    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#546E7A',
    '#26a69a',
    '#D10CE8'
]

var options = {
    series: [{
        data: [50, 22, 10, 88, 50, 25, 97, 61, 40]
    }],
    chart: {
        height: 350,
        type: 'bar',
        events: {
            click: function (chart, w, e) {
                // console.log(chart, w, e)
            }
        }
    },
    colors: colors,
    plotOptions: {
        bar: {
            columnWidth: '60%',
            distributed: true,
            borderRadius: 3,
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    xaxis: {
        categories: [
            ['Kandra'],
            ['Mango'],
            ['Parsudih'],
            'Adityapur', 'Bistupur', 'Saldih', 'Agrico', 'Saraikella', 'Kadma'
        ],
        labels: {
            style: {
                colors: colors,
                fontSize: '12px'
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#routewise"), options);
chart.render();


//Route Wise Traveller and Staff Forcast

var options = {
    series: [{
        name: 'Traveller',
        data: [20, 15, 25, 30, 17, 14, 12, 25]
    }, {
        name: 'Staff',
        data: [4, 2, 6, 4, 4, 6, 5, 2]
    }, {
        name: 'Vehicle',
        data: [2, 1, 3, 2, 2, 3, 4, 5]
    }],
    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '80%',
            borderRadius: 2,
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: ['Kandra', 'Mango', 'Parsudih', 'Sakchi', 'Adityapur', 'Tatanagar', 'Kadma', 'Saraikella'],
    },
    yaxis: {
        title: {
            text: ''
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#routewisestafftrav"), options);
chart.render();

//Route Wise Income And Expense

var options = {
    series: [{
        name: 'Income',
        data: [20, 15, 25, 30, 17, 14]
    }, {
        name: 'Expense',
        data: [12, 11, 13, 12, 21, 13]
    }],
    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '80%',
            borderRadius: 2,
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: ['Kandra', 'Mango', 'Parsudih', 'Sakchi', 'Adityapur', 'Tatanagar'],
    },
    yaxis: {
        title: {
            text: 'Route wise Income and Expense'
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#routewiseincexp"), options);
chart.render();

//Vehicle Wise Income and Expense

var options = {
    series: [{
        name: 'Income',
        data: [20, 15, 25, 30, 17, 14]
    }, {
        name: 'Expense',
        data: [2, 1, 3, 2, 2, 3]
    }],
    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '80%',
            borderRadius: 2,
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: ['KA02515', 'KA02516', 'KA02517', 'KA02518', 'KA02519', 'KA02520'],
    },
    yaxis: {
        title: {
            text: 'Vehicle wise Income and Expense'
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#vehwiseincexp"), options);
chart.render();

//ERP USER TYPE
var options = {
    series: [44, 55, 41, 17],
    chart: {
        type: 'donut',
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
        }
    }]
};

var chart = new ApexCharts(document.querySelector("#erpusertype"), options);
chart.render();

//ERP USERS FORCAST
var colors = [
    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#546E7A',
    '#26a69a',
    '#D10CE8'
]

var options = {
    series: [{
        data: [50, 22, 10, 88, 50, 25, 97, 78, 50, 22, 10, 88]
    }],
    chart: {
        height: 350,
        type: 'bar',
        events: {
            click: function (chart, w, e) {
                // console.log(chart, w, e)
            }
        }
    },
    colors: colors,
    plotOptions: {
        bar: {
            columnWidth: '60%',
            distributed: true,
            borderRadius: 3,
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    title: {
        text: 'ERP USES BY USERS FORCAST',
        align: 'center',
        floating: true
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        labels: {
            style: {
                colors: colors,
                fontSize: '12px'
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#usersforcast"), options);
chart.render();



//EduConnect Users Analysis
var options = {
    series: [75, 15, 10],
    chart: {
        type: 'donut',
    },
    labels: ['Active Users', 'InActive Users', 'Not Installed'],
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 230
            },
            legend: {
                position: 'bottom'
            }
        }
    }]
};

var chart = new ApexCharts(document.querySelector("#educonnectusers"), options);
chart.render();

//EduConnect Uses Analysis
var options = {
    series: [{
        name: "Admin",
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
    },
    {
        name: "Employee",
        data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
    },
    {
        name: 'Parent & Student',
        data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47]
    }
    ],
    chart: {
        height: 350,
        type: 'line',
        zoom: {
            enabled: false
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        width: [5, 7, 5],
        curve: 'straight',
        dashArray: [0, 8, 5]
    },
    title: {
        text: 'EduConnect App Uses Analysis',
        align: 'left'
    },
    legend: {
        tooltipHoverFormatter: function (val, opts) {
            return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
        }
    },
    markers: {
        size: 0,
        hover: {
            sizeOffset: 6
        }
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    tooltip: {
        y: [
            {
                title: {
                    formatter: function (val) {
                        return val + " (mins)"
                    }
                }
            },
            {
                title: {
                    formatter: function (val) {
                        return val + " per session"
                    }
                }
            },
            {
                title: {
                    formatter: function (val) {
                        return val;
                    }
                }
            }
        ]
    },
    grid: {
        borderColor: '#f1f1f1',
    }
};

var chart = new ApexCharts(document.querySelector("#educonnectuses"), options);
chart.render();

//LMS ANANYSIS
var options = {
    series: [{
        name: 'Courses',
        type: 'column',
        data: [23, 1, 22, 27, 13, 22, 37, 21, 44, 22, 30]
    }, {
        name: 'Users',
        type: 'area',
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
    }, {
        name: 'Time Stamp',
        type: 'line',
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
    }],
    chart: {
        height: 350,
        type: 'line',
        stacked: false,
    },
    stroke: {
        width: [0, 2, 5],
        curve: 'smooth'
    },
    plotOptions: {
        bar: {
            columnWidth: '50%',
            borderRadius: 3,
        }
    },

    fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
        }
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
        title: {
            text: 'Courses and Users',
        },
        min: 0
    },
    tooltip: {
        shared: true,
        intersect: false,
        y: {
            formatter: function (y) {
                if (typeof y !== "undefined") {
                    return y.toFixed(0);
                }
                return y;

            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#lmsanalysis"), options);
chart.render();


//Communication Analysis

var options = {
    series: [{
        name: 'App',
        data: [20, 15, 25, 30, 17, 52, 15, 24, 35, 65, 55, 45]
    },
    {
        name: 'SMS',
        data: [14, 12, 16, 14, 41, 54, 22, 32, 45, 24, 25, 15]
    },
    {
        name: 'WhatsApp',
        data: [44, 42, 56, 54, 54, 55, 21, 35, 63, 52, 35, 35]
    },
    {
        name: 'Voice',
        data: [41, 21, 61, 41, 45, 22, 45, 21, 35, 25, 65, 32]
    }, {
        name: 'Email',
        data: [42, 21, 23, 22, 52, 52, 21, 32, 51, 52, 32, 52]
    }],
    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '80%',
            endingShape: 'rounded',
            borderRadius: 2,
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
        title: {
            text: ''
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#commanalysis"), options);
chart.render();


//Inventory Analysis
var options = {
    series: [{
        name: 'Sale',
        data: [20, 15, 25, 30, 17, 52, 15, 24, 35, 65, 55, 45]
    },
    {
        name: 'Purchase',
        data: [14, 12, 16, 14, 41, 54, 22, 32, 45, 24, 25, 15]
    },
    {
        name: 'Consumption',
        data: [41, 21, 61, 41, 45, 22, 45, 21, 35, 25, 65, 32]
    }, {
        name: 'Stock',
        data: [42, 21, 23, 22, 52, 52, 21, 32, 51, 52, 32, 52]
    }],
    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '80%',
            endingShape: 'rounded',
            borderRadius: 2,
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
        title: {
            text: ''
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#inventoryanalysis"), options);
chart.render();

//HOSTEL ANALYSIS
var options = {
    series: [{
        name: 'Occupied Bed',
        data: [3, 2, 1, 3, 3, 2, 1, 0, 0, 4]
    }, {
        name: 'Vaccent Bed',
        data: [2, 2, 5, 3, 5, 4, 3, 5, 6, 2]
    }],
    chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
            show: true
        },
        zoom: {
            enabled: true
        }
    },
    responsive: [{
        breakpoint: 480,
        options: {
            legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
            }
        }
    }],
    plotOptions: {
        bar: {
            horizontal: true,
            borderRadius: 3,
        },
    },
    xaxis: {
        categories: ['Room 1', 'Room 2', 'Room 3', 'Room 4', 'Room 5', 'Room 6', 'Room 7', 'Room 8', 'Room 8', 'Room 10'],
    },
    legend: {
        position: 'right',
        offsetY: 40
    },
    fill: {
        opacity: 1
    }
};

var chart = new ApexCharts(document.querySelector("#hostelanalysis"), options);
chart.render();


//HOSTEL GROWTH ANALYSIS
var colors = [
    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#546E7A',
    '#26a69a',
    '#D10CE8'
]

var options = {
    series: [{
        data: [50, 22, 10, 88, 50, 25, 97, 78, 50, 22, 10, 88]
    }],
    chart: {
        height: 350,
        type: 'bar',
        events: {
            click: function (chart, w, e) {
                // console.log(chart, w, e)
            }
        }
    },
    colors: colors,
    plotOptions: {
        bar: {
            columnWidth: '60%',
            distributed: true,
            borderRadius: 3,
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        labels: {
            style: {
                colors: colors,
                fontSize: '12px'
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#hostelgrowth"), options);
chart.render();

//Book Movement Analysis
var options = {
    series: [{
        name: 'Issue',
        data: [20, 15, 25, 30, 17, 52, 15, 24, 35, 65, 55, 45]
    },
    {
        name: 'Return',
        data: [14, 12, 16, 14, 41, 54, 22, 32, 45, 24, 25, 15]
    },
    {
        name: 'OverDue',
        data: [41, 21, 61, 41, 45, 22, 45, 21, 35, 25, 65, 32]
    }, {
        name: 'Dimaged',
        data: [4, 2, 3, 5, 0, 2, 3, 5, 2, 0, 1, 3]
    }],
    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '80%',
            endingShape: 'rounded',
            borderRadius: 2,
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
        title: {
            text: ''
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#bookmovement"), options);
chart.render();

//Library Revenue Analysis

var options = {
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
    series: [{
        name: 'Income',
        data: [220, 215, 225, 320, 127, 522, 125, 224, 325, 625, 525, 125]
    }, {
        name: 'Expense',
        data: [14, 102, 103, 255, 250, 215, 123, 125, 152, 120, 121, 123]
    }],
    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '80%',
            borderRadius: 2,
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
        title: {
            text: ''
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#librevenue"), options);
chart.render();

//Book Category wise Analyis
var options = {
    series: [44, 55, 41, 17, 15],
    chart: {
        type: 'donut',
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
        }
    }]
};

var chart = new ApexCharts(document.querySelector("#bookcatwise"), options);
chart.render();

//Book Punlisher wise Analyis
var options = {
    series: [44, 55, 41, 17, 15],
    chart: {
        type: 'donut',
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
        }
    }]
};

var chart = new ApexCharts(document.querySelector("#bookpublishwise"), options);
chart.render();

//Book Subject wise Analyis
var options = {
    series: [44, 55, 41, 17, 15],
    chart: {
        type: 'donut',
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
        }
    }]
};

var chart = new ApexCharts(document.querySelector("#booksubwise"), options);
chart.render();

//Session wise Canteen Report
var options = {
    series: [{
        name: 'Sale',
        data: [20, 15, 25, 30, 17, 52, 15, 24, 35, 65, 55, 45]
    },
    {
        name: 'Purchase',
        data: [14, 12, 16, 14, 41, 54, 22, 32, 45, 24, 25, 15]
    },
    {
        name: 'Stock',
        data: [41, 21, 61, 41, 45, 22, 45, 21, 35, 25, 65, 32]
    }, {
        name: 'Due',
        data: [4, 2, 3, 5, 0, 2, 3, 5, 2, 0, 1, 3]
    }],
    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '80%',
            endingShape: 'rounded',
            borderRadius: 2,
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
        title: {
            text: ''
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#canteenreport"), options);
chart.render();

//Session wise Canteen Report
var colors = [
    '#28B463',
    '#2E86C1',
    '#E67E22',
    '#FF4560'
]
var options = {
    series: [{
        name: 'Collection',
        data: [20, 15, 25, 30, 17, 52, 15, 24, 35, 65, 55, 45]
    },
    {
        name: 'Purchase',
        data: [14, 12, 16, 14, 41, 54, 22, 32, 45, 24, 25, 15]
    },
    {
        name: 'Stock',
        data: [41, 21, 61, 41, 45, 22, 45, 21, 35, 25, 65, 32]
    }, {
        name: 'Due',
        data: [4, 2, 3, 5, 0, 2, 3, 5, 2, 0, 1, 3]
    }],
    chart: {
        type: 'bar',
        height: 350
    },
    colors: colors,
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '80%',
            endingShape: 'rounded',
            borderRadius: 2,
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
        title: {
            text: ''
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#messreport"), options);
chart.render();

//Month Wise Fee Collection Report
var options = {
    series: [{
        name: 'Paid',
        data: [950, 505, 401, 357, 282, 543, 421, 401, 957, 282, 543, 421]
    }, {
        name: 'Unpaid',
        data: [830, 500, 400, 300, 200, 402, 410, 401, 757, 282, 543, 721]
    }, {
        name: 'Fine',
        data: [402, 127, 151, 79, 105, 101, 200, 401, 557, 282, 543, 821]
    }],
    chart: {
        type: 'bar',
        height: 400,
        stacked: true,
    },
    plotOptions: {
        bar: {
            horizontal: true,
            borderRadius: 3,
        },
    },
    stroke: {
        width: 1,
        colors: ['#fff']
    },
    title: {
        text: ''
    },
    xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        labels: {
            formatter: function (val) {
                return val + "K"
            }
        }
    },
    yaxis: {
        title: {
            text: undefined
        },
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val + " Thousand"
            }
        }
    },
    fill: {
        opacity: 1
    },
    legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
    }
};
var chart = new ApexCharts(document.querySelector("#monthwisefeecol"), options);
chart.render();

//Income Report Category Wise
var chart;
options = {
    chart: {
        height: 280,
        type: "donut"
    },
    series: [40, 30, 15, 15],
    labels: ["Gen", "OBC", "SC", "ST"],
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
    legend: {
        show: !0,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: !1,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10
    },
    responsive: [{
        breakpoint: 600,
        options: {
            chart: {
                height: 240
            },
            legend: {
                show: !1
            }
        }
    }]
};
(chart = new ApexCharts(document.querySelector("#increportcatwise"), options)).render();


//Expense Report Category Wise
var chart;
options = {
    chart: {
        height: 280,
        type: "donut"
    },
    series: [40, 30, 15, 15],
    labels: ["Gen", "OBC", "SC", "ST"],
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
    legend: {
        show: !0,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: !1,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10
    },
    responsive: [{
        breakpoint: 600,
        options: {
            chart: {
                height: 240
            },
            legend: {
                show: !1
            }
        }
    }]
};
(chart = new ApexCharts(document.querySelector("#expreportcatwise"), options)).render();


//Finincial Status
var colors = [
    '#008FFB',
    '#FF4560',
    '#34c38f',
    '#f1b44c',
    '#775DD0',
    '#546E7A',
    '#26a69a',
    '#D10CE8'
]

var options = {
    series: [{
        data: [32542351, -7002541, 1785452, 8085215, -804250, -554800]
    }],
    chart: {
        height: 400,
        type: 'bar',
        events: {
            click: function (chart, w, e) {
                // console.log(chart, w, e)
            }
        }
    },
    colors: colors,
    plotOptions: {
        bar: {
            columnWidth: '80%',
            distributed: true,
            borderRadius: 3,
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    xaxis: {
        categories: ['Total Assets', 'Total Liabilities', 'Total Income', 'Total Expense', 'Share Holder Equity', 'Other Assets'],
        labels: {
            style: {
                colors: colors,
                fontSize: '12px'
            }
        }
    }
};

var chart = new ApexCharts(document.querySelector("#finstatus"), options);
chart.render();

//My Class Charts

var options = {
    series: [{
        name: 'Upcoming',
        data: [70, 55, 41, 67, 22, 43, 44, 55, 41, 67, 22, 43]
    }, {
        name: 'Completed',
        data: [40, 23, 20, 8, 13, 27, 44, 55, 41, 67, 22, 43]
    }, {
        name: 'Cancled',
        data: [72, 17, 15, 15, 21, 14, 44, 55, 41, 67, 22, 43]
    }],
    chart: {
        type: 'bar',
        height: 220,
        stacked: true,
        toolbar: {
            show: true
        },
        zoom: {
            enabled: true
        }
    },
    responsive: [{
        breakpoint: 480,
        options: {
            legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
            }
        }
    }],
    plotOptions: {
        bar: {
            horizontal: false,
            borderRadius: 10
        },
    },
    xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    },
    legend: {
        position: 'right',
        offsetY: 40
    },
    fill: {
        opacity: 1
    }
};

var chart = new ApexCharts(document.querySelector("#mysession"), options);
chart.render();



